import { FC, useState } from 'react'

import useOrderReviewStore from './orderReviewStore'
import { Button, H3 } from '@/common/ui'
import { useCancelOrder } from '@/trading/tradingQueries'
import Money from '@/common/models/money'
import { tradeEstimatedBrokerage } from '@/common/utils/utils'
import { OrderExpiry, orderStatus } from '@/common/types'
import { ORDER_EXPIRY, ORDER_EXPIRY_MAPPING } from '@/common/constants'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import { toast } from 'sonner'

type OrderReviewItemProps = {
    label: string
    value: string | number
}
const Item: FC<OrderReviewItemProps> = ({ label, value }) => {
    return (
        <li className="flex justify-between py-3 border-b">
            <span>{label}</span>
            <strong className="text-right ml-2">{value}</strong>
        </li>
    )
}

type Props = {
    open: boolean
    onClose: () => void
    onCancelOrder: () => void
}

export const ReviewOrderModal: FC<Props> = ({ open, onClose, onCancelOrder }) => {
    const { currentOrder } = useOrderReviewStore()
    const cancelOrder = useCancelOrder()
    const [cancelingOrder, setCancelingOrder] = useState<boolean>(false)

    if (!currentOrder) return

    const handleCancelOrder = async () => {
        setCancelingOrder(true)
        cancelOrder.mutate(currentOrder.orderId, {
            onSuccess: () => {
                onCancelOrder()
            },
            onError: () => {
                toast.error('Failed to cancel order, Please contact support')
            },
            onSettled: () => {
                setCancelingOrder(false)
            },
        })
    }

    const formatExpiryDate = (date: string) => (date ? `${new Date(date).toLocaleDateString()}` : 'NA')

    const {
        companyName,
        expiresOn,
        orderSide,
        orderType,
        units,
        securityCode,
        validity,
        status,
        orderCompletionPrice,
        orderId,
        requestedPrice,
    } = currentOrder
    const relevantPrice = orderCompletionPrice ? orderCompletionPrice : requestedPrice
    const sharesAmount = Money.of(relevantPrice).multiply(units)
    const fee = sharesAmount ? tradeEstimatedBrokerage(sharesAmount) : Money.of(0)
    const orderPending = [orderStatus['IN MARKET'], orderStatus.REQUESTED].includes(status)
    const orderFailedOrCancelled = [orderStatus.FAILED, orderStatus.CANCELLED].includes(status)
    const orderValidity =
        validity == 'Till Date'
            ? formatExpiryDate(expiresOn)
            : ORDER_EXPIRY[ORDER_EXPIRY_MAPPING[validity] as OrderExpiry]
    return (
        <>
            <Dialog open={open}>
                <DialogContent
                    onClose={onClose}
                    className="px-5 py-12 max-h-[90vh] min-h-[50vh] w-[94vw] max-w-[680px] overflow-y-auto xs:px-16 xs:py-16"
                >
                    <H3 className="text-center">
                        Review <span className="capitalize">{orderSide.toLowerCase()}</span> Order
                    </H3>
                    <ul>
                        <Item label="Order Reference" value={orderId} />
                        <Item label="Order Status" value={status} />
                        {/* Workaround until we change company name -> instrument name */}
                        <Item label="Security" value={companyName ?`${companyName} (${securityCode})` : securityCode } />
                        <Item label="Order Type" value={orderType == 'MARKET_TO_LIMIT' ? 'Market' : 'Limit'} />
                        <Item label="Expiry Date" value={orderValidity} />
                        <Item label="Shares" value={units} />
                        {requestedPrice && <Item label="Limit Price" value={requestedPrice} />}
                        {orderCompletionPrice && <Item label="Average Completion Price" value={orderCompletionPrice} />}
                        {!orderFailedOrCancelled && sharesAmount.greaterThan(Money.of(0)) ? (
                            <>
                                <Item
                                    label={`${!orderCompletionPrice ? 'Estimated ' : ''}Amount`}
                                    value={`${sharesAmount}`}
                                />
                                <Item
                                    label={`${!orderCompletionPrice ? 'Estimated ' : ''}Brokerage`}
                                    value={`${fee}`}
                                />
                                <Item
                                    label={`${!orderCompletionPrice ? 'Estimated ' : ''}Total`}
                                    value={`${orderSide == 'BUY' ? sharesAmount.add(fee) : sharesAmount.subtract(fee)}`}
                                />
                            </>
                        ) : null}
                    </ul>
                    {orderPending && (
                        <>
                            <Button
                                variant="destructive"
                                size="lg"
                                className="w-max m-auto"
                                onClick={handleCancelOrder}
                                loading={cancelingOrder}
                            >
                                Cancel Order
                            </Button>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}
