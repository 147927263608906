import { Helmet } from 'react-helmet'
import { APP } from '@/common/strings'
import { FC, useEffect, useState } from 'react'
import { H1 } from '@/common/ui/h1'
import { Holdings } from './holdings'
import { Orders } from './orders'
import Money from '@/common/models/money'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'
import { ValuesGrid } from '@/common/ui/ValuesGrid'
import { ValuesGridItem } from '@/common/ui/ValuesGridItem'
import { Tabs, TabsContent, TabsTrigger } from '@/common/ui/tabs'
import { TabsList } from '@radix-ui/react-tabs'
import { useFetchTradingAccount, useListHoldings, useListOrders } from '@/trading/tradingQueries'
import useKycStore from '@/kyc/kycStore'
import { cn, seconds } from '@/common/utils/utils'
import { analytics } from '@/common/analytics/analytics'
import { ListHoldingsResponse } from '@/common/types'

export function Portfolio() {
    const [totalInvested, setTotalInvested] = useState<number>(0)
    const { showPortfolioPage } = useFeatureFlags()
    const { kycComplete } = useKycStore()
    const { data: tradingAccount } = useFetchTradingAccount()
    const hasApprovedTradingAccount = tradingAccount?.status === 'APPROVED'
    const { data: holdings } = useListHoldings({
        enabled: kycComplete && hasApprovedTradingAccount,
        refetchInterval: seconds(20),
    })
    const { data: orders } = useListOrders({
        enabled: kycComplete && hasApprovedTradingAccount,
        refetchInterval: seconds(20),
    })

    const trackTabChange = (value: string) => {
        const selectedTabName = value === 'positions' ? 'Positions' : 'Orders'
        analytics.track(`${selectedTabName} tab selected`)
    }
    const allCostAvailable = (holdings: ListHoldingsResponse | undefined) => !holdings?.holdings.find((e) => !e.cost)
    useEffect(() => {
        if (!holdings || !holdings.holdings.length) return
        const total = holdings.holdings.reduce((acc, holding) => acc + (holding.cost || 0), 0)
        setTotalInvested(total)
    }, [holdings])

    if (!showPortfolioPage) return null

    type TabProps = {
        label: string
        value: string
    }
    const Tab: FC<TabProps> = ({ label, value }) => {
        return (
            <TabsTrigger
                value={value}
                className={cn(
                    'w-full py-3 rounded-b-none border-b-2 font-bold text-[#888]',
                    'data-[state=active]:border-primary-light data-[state=active]:text-foreground',
                )}
            >
                {label}
            </TabsTrigger>
        )
    }
    return (
        <>
            <Helmet>
                <title>Portfolio | {APP.title}</title>
            </Helmet>
            <H1>Portfolio</H1>
            <ValuesGrid>
                <ValuesGridItem label="Portfolio Value" value={`${ Money.of(holdings?.totalValue ?? 0)}`} />
                {allCostAvailable(holdings) && (
                    <ValuesGridItem label="Total Invested" value={`${Money.of(totalInvested)}`} />
                )}
                {allCostAvailable(holdings) && (
                    <ValuesGridItem
                        label="Profit / Loss"
                        value={`${Money.of((holdings?.totalValue ?? 0) - totalInvested)}`}
                    />
                )}
            </ValuesGrid>
            <Tabs defaultValue="positions" className="mt-8" onValueChange={trackTabChange}>
                <TabsList className="p-0 h-auto flex justify-center w-[200px] mb-6">
                    <Tab label="Positions" value="positions" />
                    <Tab label="Orders" value="orders" />
                </TabsList>
                <TabsContent value="positions">
                    <Holdings holdings={holdings?.holdings ?? []} />
                </TabsContent>
                <TabsContent value="orders">
                    <Orders orders={orders?.orders ?? []} />
                </TabsContent>
            </Tabs>
        </>
    )
}
