import Money from '@/common/models/money'
import { InstrumentT } from '@/common/types'
import { H1, H2 } from '@/common/ui'
import { LabelInput } from '@/common/ui/LabelInput'
import { Button } from '@/common/ui/button'
import {
    getStepSize,
    isAvailableBalanceSufficient,
    isLimitPriceValid,
    isSizeAboveMin,
    isValidPriceFormat,
    tradeEstimatedBrokerage,
} from '@/common/utils/utils'
import useTradeStore from '@/trading/tradeStore'
import { useFetchBalance } from '@/wallet/walletQueries'
import { CircleMinus, TimerReset } from 'lucide-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { OrderTypeSelector } from './OrderTypeSelector'
import { TradeMoneySummary } from './TradeMoneySummary'
import { TRADING } from '@/common/strings'
import { Spinner } from '@/common/ui/spinner'
import { useNavigate } from 'react-router'
import { useListHoldings } from './tradingQueries'
import { SecurityHead } from '@/equities/securityHead.tsx'
import PrecisionAwarePriceLabel from '@/common/models/precsionAwarePriceLabel.tsx'

type Props = {
    instrument: InstrumentT
    onSubmit: () => void
    onClose: () => void
}
export const TradeForm: FC<Props> = ({ instrument, onSubmit, onClose }) => {
    const { data: balance } = useFetchBalance()
    const {
        orderType,
        setOrderType,
        limitPrice,
        setLimitPrice,
        setApproximateInvestmentAmount,
        approximateInvestmentAmount,
        numberOfShares,
        setNumberOfShares,
        instrumentPrice,
        setInstrumentPrice,
        orderAction,
        resetState,
    } = useTradeStore()
    const [relevantPrice, setRelevantPrice] = useState<number>()
    const [estimatedCostOfShares, setEstimatedCostOfShares] = useState<Money>()
    const [sharesCurrentlyHeld, setSharesCurrentlyHeld] = useState<number>()
    const { data: holdingsData } = useListHoldings()
    const navigate = useNavigate()
    const relevantPriceLabel = relevantPrice ? PrecisionAwarePriceLabel.of(relevantPrice) : PrecisionAwarePriceLabel.of(0)

    // If sell order, get available holdings of instrument
    useEffect(() => {
        if (holdingsData) {
            const holding = holdingsData?.holdings.find((h) => h.securityCode === instrument.securityCode)
            if (holding) {
                setSharesCurrentlyHeld(holding.units)
            } else {
                setSharesCurrentlyHeld(0)
            }
        }
    }, [holdingsData, setNumberOfShares, instrument.securityCode, numberOfShares])

    // Set instrument price
    const lastPrice = instrument.priceInfo.lastPrice || instrument.priceInfo.closingPrice
    useEffect(() => {
        setInstrumentPrice(lastPrice)
    }, [lastPrice, setInstrumentPrice])

    const getMinShares = (sharesCurrentlyHeld: number | undefined, price: number) => {
        return (sharesCurrentlyHeld && sharesCurrentlyHeld > 0) ? price : Math.ceil(500 / price)
    }
    const setSharesAndAmountToMin = useCallback(
        (price: number) => {
            const min = getMinShares(sharesCurrentlyHeld, price)
            setNumberOfShares(min)
            const minInvestment = Money.of(min * price * 1.02)
            setApproximateInvestmentAmount(minInvestment.valueWithPrecision(2))
        },
        [setNumberOfShares, setApproximateInvestmentAmount],
    )

    // Set initial order type to Limit if market is closed, otherwise Market to Limit
    const isMarketClosed = instrument.priceInfo.status !== 'ACTIVE'
    const initialOrderType = isMarketClosed ? 'LIMIT' : 'MARKET_TO_LIMIT'
    useEffect(() => {
        // If Market to Limit, default the num of shares to the minimum
        if (!orderType) {
            setOrderType(initialOrderType)
            setSharesAndAmountToMin(lastPrice)
            setLimitPrice(Money.of(lastPrice).valueWithPrecision(2))
        }
    }, [
        orderType,
        setOrderType,
        initialOrderType,
        setSharesAndAmountToMin,
        lastPrice,
        approximateInvestmentAmount,
        setLimitPrice,
    ])

    // Set relevant price for calculations
    useEffect(() => {
        setRelevantPrice(orderType === 'LIMIT' ? limitPrice : instrumentPrice)
    }, [orderType, limitPrice, instrumentPrice])

    // Set estimated cost of shares
    useEffect(() => {
        if (!relevantPrice || !numberOfShares) return
        setEstimatedCostOfShares(
            Money.of(relevantPrice).multiply(
                orderType === 'LIMIT' || orderAction === 'SELL' ? numberOfShares : numberOfShares * 1.02,
            ),
        )
    }, [relevantPrice, numberOfShares, orderType, orderAction])

    const clearForm = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        resetState()
        setOrderType(initialOrderType)
        initialOrderType === 'MARKET_TO_LIMIT' && setSharesAndAmountToMin(lastPrice)
    }

    const navToWallet = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        onClose()
        navigate('/wallet')
    }

    const handlePricePerUnitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (isValidPriceFormat(parseFloat(value), instrumentPrice ?? 0)) {
            const unitPrice = Money.of(parseFloat(value)).valueWithPrecision(2)
            setLimitPrice(unitPrice)
            const approxInvestmentAmount = Money.of(unitPrice * (numberOfShares ?? 0)).valueWithPrecision(2)
            setApproximateInvestmentAmount(approxInvestmentAmount)
        } else {
            setLimitPrice(undefined)
            setApproximateInvestmentAmount(undefined)
        }
    }

    const handleInvestmentAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (isValidPriceFormat(parseFloat(value), instrumentPrice ?? 0)) {
            const amount = Money.of(parseFloat(value)).valueWithPrecision(2)
            setApproximateInvestmentAmount(amount)
            if (!limitPrice) setLimitPrice(lastPrice)
            setNumberOfShares(Math.floor(amount / 1.02 / (relevantPrice ?? lastPrice)))
        } else {
            setApproximateInvestmentAmount(undefined)
            setNumberOfShares(0)
        }
    }

    const handleNumberOfSharesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (value === '' || !isNaN(parseInt(value))) {
            const shares = parseInt(value)
            setNumberOfShares(shares)
            const approxInvestmentAmount = Money.of(shares * (relevantPrice ?? 0) * 1.02).valueWithPrecision(2)
            setApproximateInvestmentAmount(approxInvestmentAmount)
        } else {
            setNumberOfShares(0)
            setApproximateInvestmentAmount(undefined)
        }
    }

    const handleMinimumTradeSizeClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (orderType === 'LIMIT') {
            setSharesAndAmountToMin(lastPrice ?? 0)
            setLimitPrice(lastPrice)
        } else {
            setSharesAndAmountToMin(relevantPrice ?? 0)
        }
    }

    if (!instrumentPrice || !orderType || !orderAction) return <Spinner />

    return (
        <div>
            <H2 className="text-center">Place {orderAction === 'BUY' ? 'Buy' : 'Sell'} Order</H2>
            <p className="text-xs text-center mb-4 text-muted-foreground">{TRADING.liveTradingNote}</p>

            <form className="flex flex-col gap-4" onSubmit={onSubmit}>
                <div className="grid grid-cols-1 gap-y-4 mt-2">
                    <div className="grid grid-cols-1 sm:grid-cols-2">
                        <SecurityHead className="mb-2" security={instrument} />
                        <div className="flex flex-col sm:items-end">
                            <H1 className="mb-0 mt-2">{`$${Money.of(instrumentPrice).valueWithPrecision(instrumentPrice < 0.1 ? 3 : 2)}`}</H1>
                            <p className="flex gap-2 text-xs lg:text-sm py-2 text-muted-foreground">
                                <TimerReset size={18} className="-mt-0.5 shrink-0" />
                                <span className="lg:-mt-0.5">20 minute delayed pricing</span>
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between pb-4 my-2 border-b-1 col-span-1">
                        <div className="flex text-sm items-center gap-2">
                            <span>Available {orderAction === 'BUY' ? 'funds' : 'units'}:</span>
                        </div>
                        <div className="flex gap-2 items-center">
                            <strong>
                                {orderAction === 'BUY' ? `${Money.of(balance?.availableBalance)}` : sharesCurrentlyHeld}
                            </strong>
                            {orderAction === 'BUY' && (
                                <Button
                                    midClassName="py-1"
                                    size="sm"
                                    variant="tertiary"
                                    className="text-xs border-0"
                                    onClick={navToWallet}
                                >
                                    Add funds
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="flex gap-4 w-full flex-col text-right">
                        <div className="">
                            <OrderTypeSelector
                                orderType={orderType}
                                setOrderType={setOrderType}
                                defaultOrderType={initialOrderType}
                                isMarketClosed={isMarketClosed}
                            />
                        </div>
                        {orderType === 'LIMIT' && (
                            <div className="border-t-1 border-b-1 py-4">
                                <LabelInput
                                    className="text-right"
                                    isVertical={false}
                                    type="number"
                                    value={limitPrice}
                                    onChange={handlePricePerUnitChange}
                                    prefixIcon={<div className="font-light text-[#6B7280]">$</div>}
                                    label="Price per unit:"
                                    placeholder="0"
                                    id="number-of-shares"
                                    step="0.01"
                                />
                                {orderType === 'LIMIT' &&
                                limitPrice &&
                                !isLimitPriceValid(limitPrice, instrumentPrice) ? (
                                    <p className="text-xs mt-3 text-red-700">{`Price must be within 5% of last price and multiple of ${getStepSize(limitPrice)}`}</p>
                                ) : null}
                            </div>
                        )}
                        {orderAction === 'SELL' || orderType === 'LIMIT' ? (
                            <div className={`${orderAction === 'BUY' && 'border-b-1 pb-4'}`}>
                                <LabelInput
                                    className="w-1/2 text-right"
                                    type="number"
                                    isVertical={false}
                                    value={numberOfShares}
                                    onChange={handleNumberOfSharesChange}
                                    label="Units:"
                                    placeholder="0"
                                    step=".01"
                                    id="number-of-shares"
                                />
                            </div>
                        ) : null}
                        {orderAction === 'BUY' && (
                            <LabelInput
                                type="number"
                                className="text-right"
                                isVertical={false}
                                value={approximateInvestmentAmount}
                                prefixIcon={<div className="font-light text-[#6B7280]">$</div>}
                                onChange={handleInvestmentAmountChange}
                                label="Investment amount:"
                                placeholder="0"
                                step=".01"
                                id="investment-amount"
                            />
                        )}

                        <div className="self-end text-xs -mt-2">
                            {numberOfShares && numberOfShares >= 0 ? numberOfShares : 0} units @ {relevantPriceLabel.toString()}
                        </div>
                    </div>
                </div>
                {orderAction === 'BUY' &&
                relevantPrice &&
                // sharesCurrentlyHeld === 0 &&
                (!numberOfShares || !isSizeAboveMin(sharesCurrentlyHeld, relevantPrice, numberOfShares)) ? (
                    <p className="text-xs -mt-2 self-end text-red-700">
                        Below minimum trade size.{' '}
                        <a className="text-inherit underline cursor-pointer" onClick={handleMinimumTradeSizeClick}>
                            Set to minimum
                        </a>
                    </p>
                ) : null}
                {orderAction === 'SELL' &&
                sharesCurrentlyHeld &&
                numberOfShares &&
                numberOfShares > sharesCurrentlyHeld ? (
                    <p className="text-sm text-red-700">You do not have enough shares to sell</p>
                ) : null}
                {orderType === 'LIMIT' && !limitPrice ? (
                    <p className="text-sm text-red-700">Please add a price per unit</p>
                ) : (
                    <>
                        {numberOfShares ? (
                            <div className="border-t-1 border-b-1 py-4">
                                <TradeMoneySummary
                                    fee={tradeEstimatedBrokerage(estimatedCostOfShares)}
                                    amount={estimatedCostOfShares ?? Money.ZERO}
                                    availableFunds={Money.of(balance?.availableBalance)}
                                    isSellOrder={orderAction === 'SELL'}
                                    displayWithPrecision={2}
                                />
                            </div>
                        ) : null}
                    </>
                )}
                <div className="flex flex-col-reverse sm:flex-row sm:justify-between mt-4 gap-4">
                    <div>
                        {orderAction === 'BUY' && (
                            <Button variant="link" onClick={clearForm} className="p-0 m-0">
                                <CircleMinus size={16} />
                                Clear form
                            </Button>
                        )}
                    </div>
                    <Button
                        disabled={
                            (orderAction === 'BUY' &&
                                (!isAvailableBalanceSufficient(
                                    Money.of(balance?.availableBalance),
                                    estimatedCostOfShares ?? Money.ZERO,
                                ) ||
                                    (sharesCurrentlyHeld === 0 &&
                                        !isSizeAboveMin(sharesCurrentlyHeld, relevantPrice ?? 0, numberOfShares ?? 0)))) ||
                            !numberOfShares ||
                            (orderType === 'LIMIT' && !isLimitPriceValid(limitPrice ?? 0, instrumentPrice)) ||
                            (orderAction === 'SELL' && numberOfShares > (sharesCurrentlyHeld ?? 0))
                        }
                        type="submit"
                        variant="secondary"
                        size="lg"
                    >
                        Review Trade
                    </Button>
                </div>
            </form>
        </div>
    )
}
