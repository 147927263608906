import moment from 'moment'
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts'
import Money from '@/common/models/money.ts'
import PrecisionAwarePriceLabel from '@/common/models/precsionAwarePriceLabel.tsx'

const CustomTooltip = ({ payload }: { payload: any }) => {
    const amount = payload[0]?.payload.y
    const date = moment(payload[0]?.payload.x).format('DD MMM YY').toString()
    return (
        <div className="h-[66px] rounded-md border border-slate-200 flex-col justify-start items-center inline-flex">
            <div className="px-2 py-1.5 bg-white rounded-md flex-col justify-start items-start flex">
                <div className="px-2 py-1.5 bg-white justify-start items-center gap-2 inline-flex">
                    <div className="text-center">
                        <span className="text-black text-base font-bold">{Money.of(amount).toString()}</span>
                        <br />
                        <span className="text-stone-600 text-sm">{date}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function PortfolioValueGraph({
    data
}: {
    data: { x: string; y: number }[]
}) {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <AreaChart data={data || []} margin={{ top: 10, left: 25, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorClose" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#E4FBC6" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#E4FBC6" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis
                    minTickGap={data.length * 3}
                    tickCount={2}
                    tickSize={0}
                    tickFormatter={(datestring: string) => {
                        return moment(datestring).format('MMM DD').toString()
                    }}
                    tickMargin={6}
                    padding="gap"
                    dataKey="x"
                    interval="preserveEnd"
                    strokeOpacity={0.4}
                />
                <YAxis
                    strokeWidth={1}
                    strokeOpacity={0.2}
                    tickCount={2}
                    tickFormatter={(value: number) => `${PrecisionAwarePriceLabel.of(value, true)}`}
                    padding={{ bottom: 10 }}
                    dataKey="y"
                    type="number"
                />
                <Tooltip content={({ payload }) => <CustomTooltip payload={payload} />} />
                {!data ||
                    (data?.length === 0 && (
                        <text x="50%" y="50%" style={{ fill: '#32871D' }} textAnchor="middle">
                            Loading
                        </text>
                    ))}
                {data && data?.length > 0 && (
                    <Area
                        strokeWidth={2}
                        type="linear"
                        dataKey="y"
                        stroke="#32871D"
                        fillOpacity={1}
                        fill="url(#colorClose)"
                    />
                )}
            </AreaChart>
        </ResponsiveContainer>
    )
}
