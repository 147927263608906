import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AppNav } from '@/common/navigation/AppNav'
import { TopNavMobile } from '@/common/navigation/TopNav'
import useUserStore from '@/user/userStore'
import { useEffect, useState } from 'react'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { analytics } from '@/common/analytics/analytics'
import ProfileDropdown from '../navigation/ProfileDropdown'
import { Logo } from '../assets/svg'
import { Footer } from './Footer'
import InstrumentSearch from '../navigation/InstrumentSearch'
import { useFeatureFlags } from '../featureFlags/featureFlags'
import useDetectScroll from '@smakss/react-scroll-direction'

export const AppContainer = () => {
    const [ready, setReady] = useState(false)

    const { user } = useUserStore()
    const navigate = useNavigate()
    const location = useLocation()
    const { scrollDir, scrollPosition } = useDetectScroll();
    const { showCompanySearchBar } = useFeatureFlags()

    useEffect(() => {
        if (!user) return

        if (!user.salutation) {
            navigate('/onboarding/name')
            return
        }

        if (!user.addresses?.residentialAddress) {
            navigate('/onboarding/address')
            return
        }

        if (!user.mobile) {
            navigate('/onboarding/mobile-number')
            return
        }

        if (!user.occupation) {
            navigate('/onboarding/survey')
            return
        }

        setReady(true)
    }, [user, navigate])

    useEffect(() => {
        console.log("Tracking scroll analytics " + scrollDir + " " + location.pathname)
        analytics.track('PageScrolled',
            {
                location: location.pathname.slice(1),
                direction: scrollDir
            })
    }, [location.pathname, ready, scrollDir, scrollPosition])

    useEffect(() => {
        if (!ready) return
        analytics.page()
    }, [location, ready])

    if (!ready) return <FullScreenLoader dark />

    return (
        <div className="relative h-screen flex flex-col">
            <div className="fixed top-0 left-0 w-full h-[80px] flex justify-between items-center px-6 md:px-12 bg-white z-10">
                <NavLink to="/">
                    <Logo width={100} height={30} />
                </NavLink>
                <div className="flex items-center gap-2">
                    {showCompanySearchBar && (
                        <span className="hidden md:block">
                            <InstrumentSearch />
                        </span>
                    )}
                    <ProfileDropdown user={user!} />
                    <TopNavMobile />
                </div>
            </div>

            <div className="flex flex-grow relative pt-[80px]">
                <div className="hidden md:block sticky top-[80px] min-w-[260px] w-1/4 max-w-[330px] h-[calc(100vh-80px)]">
                    <AppNav />
                </div>

                <main className="relative flex-grow overflow-y-auto" onScroll={() =>console.log("*************")}>
                    <div id="appContainer" className="max-w-screen-xl px-6 py-8 md:py-6 md:pl-8 md:pr-12"  onScroll={() =>console.log("!!!!!!")}>
                        <Outlet />
                        <Footer />
                    </div>
                </main>
            </div>
        </div>
    )
}
