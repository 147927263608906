import { useState } from 'react'

import { HistoricalPricesRangePreset } from '@/common/types'
import { useFetchEquityPrices, useFetchHistoricalPrice } from '@/trading/tradingQueries'
import { HistoricalPriceChart } from './historicalPriceChart'
import { cn } from '@/common/utils/utils'
import { Button, H2 } from '@/common/ui'
import { analytics } from '@/common/analytics/analytics'
import { TimerReset } from 'lucide-react'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { PriceDiff } from '@/common/ui/PriceDiff'
import PrecisionAwarePriceLabel from '@/common/models/precsionAwarePriceLabel.tsx'

const rangePresets: { range: HistoricalPricesRangePreset; label: string; showOnMobile?: boolean }[] = [
    { range: '1W', label: '1W', showOnMobile: true },
    { range: '1M', label: '1M' },
    { range: '6M', label: '6M' },
    { range: 'YTD', label: 'YTD' },
    { range: '1Y', label: '1Y', showOnMobile: true },
    { range: '3Y', label: '3Y' },
    { range: '5Y', label: '5Y', showOnMobile: true },
    { range: 'ALL', label: 'ALL', showOnMobile: true },
]

export const EquityPriceAndGraph = ({ securityCode }: { securityCode: string }) => {
    const [selectedRange, setSelectedRange] = useState<HistoricalPricesRangePreset>('1W')
    const { data: equityPrices } = useFetchEquityPrices(securityCode)
    const { data: pricePoints } = useFetchHistoricalPrice(securityCode, selectedRange)

    const currentPrice = equityPrices?.lastPrice || equityPrices?.closingPrice

    if (!securityCode || !equityPrices) return <FullScreenLoader />

    const handlePresetClick = (range: HistoricalPricesRangePreset) => {
        setSelectedRange(range)
        analytics.track('EquityDetails PresetRangeClicked', {
            presetRange: range,
        })
    }

    return (
        <div>
            <div className="flex flex-row items-end">
                <div className="flex flex-col shrink-0">
                    {currentPrice && (
                        <H2 className="mb-0">{`${PrecisionAwarePriceLabel.of(currentPrice)}`}</H2>
                    )}
                    {equityPrices?.percentageChange ? (
                        <p className="flex gap-2 font-bold">
                            <PriceDiff
                                value={equityPrices?.pointsChange}
                                label={`${equityPrices?.percentageChange}%`}
                            />
                            <span className="text-accent-foreground">Today</span>
                        </p>
                    ) : null}
                </div>

                <ul className="w-full flex overflow-x-auto justify-end space-x-2 pr-4 mt-2">
                    {rangePresets.map((preset) => (
                        <li key={preset.range} className={`${preset.showOnMobile ? 'block' : 'hidden'} sm:block`}>
                            <Button
                                onClick={() => handlePresetClick(preset.range)}
                                className={cn('items-center h-auto w-10 leading-[10px] mb-1 font-normal rounded-3xl', {
                                    'bg-secondary-light': selectedRange === preset.range,
                                })}
                                size="sm"
                                variant="tertiary"
                            >
                                {preset.label}
                            </Button>
                        </li>
                    ))}
                </ul>
            </div>
            <p className="flex gap-2 text-xs lg:text-sm py-2 text-muted-foreground mb-4 mt-1">
                <TimerReset size={18} className="-mt-0.5 shrink-0" />
                <span className="lg:-mt-0.5">20 minute delayed pricing</span>
            </p>
            <HistoricalPriceChart rangePreset={selectedRange} data={pricePoints} />
        </div>
    )
}
