import { DataTable } from '@/common/ui/data-table'
import { HoldingT } from '@/common/types'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import Money from '@/common/models/money'
import { Button } from '@/common/ui'
import useTradeModalStore from '@/trading/tradeModalStore'
import { PriceDiff } from '@/common/ui/PriceDiff.tsx'
import { SecurityHead } from '@/equities/securityHead.tsx'

type holdingsProps = {
    holdings: HoldingT[]
}

export function Holdings({ holdings }: holdingsProps) {
    const { setSecurityCodes, setOrderAction } = useTradeModalStore()

    const columns: ColumnDef<HoldingT>[] = useMemo(
        () => [
            {
                accessorKey: 'securityCode',
                enableSorting: true,
                header: () => {
                    return <span className="text-primary font-bold">Company</span>
                },
                cell: ({ row }) => {
                    const instrumentCode = row.original.securityCode
                    return (
                        <SecurityHead
                            nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                            security={{
                                securityCode: instrumentCode,
                                securityName: row.original.name,
                            }}
                        />
                    )
                },
            },
            // {
            //     accessorKey: 'dailyChange',
            //     enableSorting: true,
            //     sortingFn: (rowA, rowB) => (rowB.original.dailyChangeValue ?? 0) - (rowA.original.dailyChangeValue ?? 0),
            //     header: () => {
            //         return <span className="text-primary font-bold">Change Today</span>
            //     },
            //     cell: ({ row }) => {
            //         const dailyChangeValue = Money.of(row.original.dailyChangeValue)
            //         return (
            //             <div className="self-stretch justify-start items-start gap-0.5 flex">
            //                 <PriceDiff
            //                     label={
            //                         Number.isFinite(row.original.dailyChangePercent) && row.original.dailyChangePercent != 0
            //                             ? `${dailyChangeValue} (${Math.abs(row.original.dailyChangePercent!).toFixed(2)}%)`
            //                             : `${dailyChangeValue}`
            //                     }
            //                     value={dailyChangeValue.value}
            //                 />
            //             </div>
            //         )
            //     },
            // },
            {
                accessorKey: 'lastPrice',
                enableSorting: true,
                header: () => {
                    return <span className="text-primary font-bold">Last Price</span>
                },
                cell: ({ row }) => {
                    const lastPrice = Money.of(row.original.lastPrice)
                    return <>{`${lastPrice}`}</>
                },
            },
            {
                accessorKey: 'units',
                enableSorting: true,
                header: () => {
                    return <span className="text-primary font-bold">Shares</span>
                },
            },
            {
                accessorKey: 'currentValue',
                enableSorting: true,
                header: () => {
                    return <span className="text-primary font-bold">Value</span>
                },
                cell: ({ row }) => {
                    const lastPrice = Money.of(row.original.lastPrice)
                    const sharesHeld = row.original.units
                    return <>{`${lastPrice.multiply(sharesHeld)}`}</>
                },
            },
            {
                accessorKey: 'totalChange',
                enableSorting: true,
                sortingFn: (rowA, rowB) => {
                    const changeA = rowA.original.currentValue - (rowA.original.cost ?? 0)
                    const changeB = rowB.original.currentValue - (rowB.original.cost ?? 0)
                    return changeB - changeA
                },
                header: () => {
                    return <span className="text-primary font-bold">Change</span>
                },
                cell: ({ row }) => {
                    const totalChange = Money.of(row.original.currentValue - (row.original.cost ?? 0))
                    const changePercent = (totalChange.value / (row.original.cost ?? 0)) * 100
                    return (
                        <div className="self-stretch justify-start items-start gap-0.5 flex">
                            <PriceDiff
                                label={
                                    Number.isFinite(changePercent)
                                        ? `${totalChange} (${Math.abs(changePercent).toFixed(2)}%)`
                                        : `${totalChange} (0%)`
                                }
                                value={totalChange.value}
                            />
                        </div>
                    )
                },
            },
            {
                accessorKey: 'trade',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Trade</span>
                },
                cell: ({ row }) => {
                    return (
                        <div className="flex gap-2">
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() => {
                                    setSecurityCodes([row.original.securityCode])
                                    setOrderAction('BUY')
                                }}
                            >
                                Buy More
                            </Button>
                            <Button
                                variant="outline"
                                size="sm"
                                onClick={() => {
                                    setSecurityCodes([row.original.securityCode])
                                    setOrderAction('SELL')
                                }}
                            >
                                Sell
                            </Button>
                        </div>
                    )
                },
            },
        ],
        [setSecurityCodes, setOrderAction],
    )

    return <DataTable columns={columns} data={holdings.length ? holdings : []} noDataMessage="No shares held yet..." />
}
